import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import ArrowLeft from '../../images/iconArrowLeft.svg';
import ArrowRight from '../../images/iconArrowRight.svg';
import { baseColors } from '../../constants';

const Wrapper = styled.ul`
  display: flex;
  list-style: none;
  margin: 1rem 0 0 0;
  padding: 0;
  font-size: .75rem;
`;

const ButtonItem = styled.li`
  display: flex;
  flex: 1;
`;

const NextItem = styled(ButtonItem)`
  justify-content: flex-end;
`;

const PaginationLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${baseColors.grey.dark};
  text-decoration: none;

  &:hover {
    color: ${baseColors.black};

    svg {
      stroke: ${baseColors.black};
    }
  }

  svg {
    margin: 0 .25rem;
    stroke: ${baseColors.grey.dark};
  }
`;

const PageLink = styled(PaginationLink, { shouldForwardProp: prop => isPropValid(prop) && prop !== 'isCurrentPage' })`
  justify-content: center;
  height: 40px;
  width: 40px;
  margin: 0 1px;
  border-radius: 40px;
  ${props => props.isCurrentPage && `
    background-image: linear-gradient( to top, ${baseColors.white} 0%, ${baseColors.grey.base} 90% );
    transition: background-image .15s ease-out;

    &:hover,
    &:focus {
      background-image: linear-gradient( to top, #FFFFFF 0%, #DDDDDD 90% );
    }
  `}

  ${props => !props.isCurrentPage && `
    &:hover {
      border: 1px solid ${baseColors.grey.base};
    }
  `}
`;

const Pagination = ({
  pageCount = 0,
  currentPage
}) => {
  if (pageCount <= 0) return null;

  const isFirstPage = currentPage <= 1;
  const isLastPage = currentPage >= pageCount;

  const renderPageLinks = pageCount => Array.from({ length: pageCount }).map((_, i) => (
    <li key={`${i}-${pageCount}`}>
      <PageLink
        isCurrentPage={i + 1 === currentPage ? true : false}
        to={i === 0 ? '/' : `/page/${i + 1}`}
      >{i + 1}</PageLink>
    </li>
  ));

  return (
    <nav aria-label="pagination">
      <Wrapper>
        <ButtonItem key="previous-page">
          {!isFirstPage &&
            <PaginationLink to={currentPage > 2 ? `/page/${currentPage - 1}` : '/'}>
              <ArrowLeft />
              Previous
            </PaginationLink>
          }
        </ButtonItem>

        {renderPageLinks(pageCount)}

        <NextItem key="next-page">
          {!isLastPage && 
            <PaginationLink to={currentPage < pageCount ? `/page/${currentPage + 1}` : '/'}>
              Next
              <ArrowRight />
            </PaginationLink>
          }
        </NextItem>
      </Wrapper>
    </nav>
  );
}

export default Pagination;

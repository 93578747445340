import React from 'react';
import { graphql } from 'gatsby';
import PageLayout from '../components/PageLayout';
import MetaTags from '../components/MetaTags';
import Banner from '../components/Banner';
import Posts from '../components/Posts';
import Pagination from '../components/Pagination';
import { selectPageContent } from '../selectors/selectPageContent';
import { selectPosts } from '../selectors/selectPosts';

export const query = graphql`
  query POST_LISTING_TEMPLATE_QUERY($skip: Int!, $limit: Int!) {
    markdownRemark(fileAbsolutePath: { regex: "/content/pages/" }, fields: { slug: { eq: "/" } }) {
      ...PAGE_CONTENT_FRAGMENT
    }

    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___datetime] }
      filter: { fileAbsolutePath: { regex: "/content/posts/" } }
      limit: $limit
      skip: $skip
    ) {
      ...ALL_POSTS_FRAGMENT
    }
  }
`;

const HomepageTemplate = ({
  data,
  location: { href },
  pageContext: { pageCount, currentPage }
}) => {
  const { title, excerpt } = selectPageContent(data);
  const { posts } = selectPosts(data);

  return (
    <PageLayout>
      <MetaTags url={href} />
      <Banner title={title} excerpt={excerpt} />
      <Posts posts={posts} />
      <Pagination pageCount={pageCount} currentPage={currentPage} />
    </PageLayout>
  );
};

export default HomepageTemplate;
